import React, { useState, useEffect } from 'react';
import './Pricing.css';

import enterpriseLocations from '../Images/locations.svg'
import enterpriseAccounts from '../Images/accounts.svg'
import enterpriseHistory from '../Images/history.svg'
import enterpriseSupport from '../Images/support.svg'

const Pricing = () => {
    const [isMonthly, setIsMonthly] = useState(false);
    const [mftServices, setMftServices] = useState('');
    const [protocols, setProtocols] = useState('');
    const [regions, setRegions] = useState('');
    const [connectionCredits, setConnectionCredits] = useState(0);
    const [currency, setCurrency] = useState('GBP');
    const [hoveredTooltip, setHoveredTooltip] = useState(null); // State to track hovered info symbol

    const togglePricing = () => {
        setIsMonthly(!isMonthly);
    };

    const handleCurrencyChange = (e) => {
        setCurrency(e.target.value);
    };

    useEffect(() => {
        const mft = parseInt(mftServices, 10) || 0;
        const region = parseInt(regions, 10) || 0;
        const protocol = parseInt(protocols, 10) || 0;
        setConnectionCredits((mft * region) * protocol);
    }, [mftServices, regions, protocols]);

    // Updated pricing data with fixed prices per currency
    const pricingData = [
        {
            tier: 'Starter',
            fixedPrices: {
                GBP: { annual: 94, monthly: 113 },
                USD: { annual: 127, monthly: 153 },
                EUR: { annual: 117, monthly: 141 },
                CHF: { annual: 111, monthly: 133 }
            },
            monthlyLink: 'https://buy.stripe.com/7sI9Ci5RR9f2bNm5ko',
            annualLink: 'https://buy.stripe.com/28o5m23JJezmdVueUU',
            connections: [
                'Monitor: SFTP',
                'On: 1 x Target',
                'From: 1 x Cloud-Based Monitoring Agent (Choose from US, EUR, APAC)',
            ],
            using: [
                'Threpoly Security Score',
                'Protocol Vulnerability Checks',
                'Live Performance Monitoring',
                'Congestion Forecasting',
            ],
            with: [
                '1 x User Account',
                '24hrs of Monitoring History',
                'Outage Alerting via Email',
                'Support Documentation and KBs',
            ],
        },
        {
            tier: 'Professional',
            fixedPrices: {
                GBP: { annual: 342, monthly: 411 },
                USD: { annual: 463, monthly: 555 },
                EUR: { annual: 425, monthly: 511 },
                CHF: { annual: 401, monthly: 481 }
            },
            monthlyLink: 'https://buy.stripe.com/fZe15M2FF76UcRq146',
            annualLink: 'https://buy.stripe.com/6oE15M7ZZ62Q5oYaEF',
            connections: [
                'Monitor: SFTP & FTPS',
                'On: up-to 2 x Targets',
                'From: up-to 2 x Cloud-Based Monitoring Agents (Choose from US, EUR, APAC)',
            ],
            using: [
                'Threpoly Security Score',
                'Protocol Vulnerability Checks',
                'Upload/Download Speed Testing',
                'Live Performance Monitoring',
                'Congestion Forecasting',
                'Reporting Engine',
            ],
            with: [
                'Up-to 5 x User Accounts',
                'Role-Based Access Control',
                'Single Sign-On (SSO)',
                '90 Days of Monitoring History',
                'Outage & Conditional Alerting via Email, SMS and WhatsApp',
                'Access to Support Documentation and KBs',
                'Email-Based Technical Support',
            ],
        },
        {
            tier: 'Premium',
            fixedPrices: {
                GBP: { annual: 563, monthly: 677 },
                USD: { annual: 761, monthly: 915 },
                EUR: { annual: 699, monthly: 841 },
                CHF: { annual: 659, monthly: 793 }
            },
            monthlyLink: 'https://buy.stripe.com/fZe7uaeonaj64kU9AD',
            annualLink: 'https://buy.stripe.com/cN215MfsrfDqg3C8wB',
            connections: [
                'Monitor: SFTP & FTPS',
                'On: up-to 3 x Targets',
                'From: up-to 3 x Cloud-Based Monitoring Agents (Choose from US, EUR, APAC)',
            ],
            using: [
                'Threpoly Security Score',
                'Protocol Vulnerability Checks',
                'Upload/Download Speed Testing',
                'Live Performance Monitoring',
                'Congestion Forecasting',
                'Reporting Engine',
            ],
            with: [
                'Up to 10 x User Accounts',
                'Role-Based Access Control',
                'Single Sign-On (SSO)',
                '365 Days of Monitoring History',
                'Outage, Slowdown & Conditional Alerting via Email, SMS and WhatsApp',
                'Custom Branded Interface',
                'Exportable Reports with Custom Branding',
                'Access to Support Documentation and KBs',
                'Email-Based Technical Support',
            ],
        },
    ];

    const getCardClass = (plan) => {
        let className = `${plan.tier.toLowerCase()}-card`;

        if (connectionCredits > 1 && plan.tier === 'Starter') {
            className += ' not-selected';
        } else if (connectionCredits > 8 && plan.tier === 'Professional') {
            className += ' not-selected';
        }

        return className;
    };

    const resetCalc = () => {
        setMftServices('');
        setRegions('');
        setProtocols('');
    };

    // Format the price with the appropriate currency symbol.
    const getFormattedPrice = (price) => {
        switch (currency) {
            case 'GBP':
                return `£${price}`;
            case 'USD':
                return `$${price}`;
            case 'EUR':
                return `${price}€`;
            case 'CHF':
                return `Fr.${price}`;
            default:
                return `${price}`;
        }
    };

    const tooltips = {
        mftServices: "MFT Services are the number of installed instances of the MFT service to be tested.",
        regions: "The number of regions the services will be tested from. A single MFT service can and should be tested from multiple origin regions.",
        protocols: "The number of file transfer protocols (SFTP, FTPS, etc.) that will be tested on each MFT service, from each origin region.",
    };

    return (
        <div className='container' style={{ backgroundColor: 'var(--secondary-color)', maxWidth: '100%' }}>
            <div className="pricing-container">
                <h1 className='page-title'>Pricing</h1>
                <h2 className='page-sub-heading'>Get Started with a Threpoly Plan Today</h2>
                <p className='page-sub-heading'>
                    Secure and monitor your critical infrastructure today or <a href="/contact">try our FREE 14-day trial</a>.
                </p>

                <div className="switch-container">
                    <span className='switch-payment' onClick={togglePricing}>
                        {!isMonthly ? 'Switch to Pay Monthly' : 'Switch to Pay Annually (Up to 20% discount)'}
                    </span>

                    <select value={currency} onChange={handleCurrencyChange} className="currency-dropdown">
                        <option value="GBP">£GBP</option>
                        <option value="USD">$USD</option>
                        <option value="EUR">€EUR</option>
                        <option value="CHF">CHF</option>
                    </select>
                </div>

                <div className="pricing-cards">
                    {pricingData.map((plan, index) => {
                        // Choose the price based on the current billing option and selected currency.
                        const priceType = isMonthly ? 'monthly' : 'annual';
                        const planPrice = plan.fixedPrices[currency][priceType];

                        // For the "WAS" price in non-monthly view, we assume the monthly price.
                        const wasPrice = plan.fixedPrices[currency].monthly;

                        return (
                            <div key={index} className={`pricing-card ${getCardClass(plan)}`}>
                                <h2>{plan.tier}</h2>
                                {!isMonthly && (
                                    <div className="old-price-wrapper">
                                        <p className='was-text'>WAS</p>
                                        <p className={plan.tier === 'Professional' ? 'old-price-number-dark' : 'old-price-number'}>
                                            {getFormattedPrice(wasPrice)}
                                        </p>
                                    </div>
                                )}
                                <div className="price-wrapper">
                                    <p className='price-number'>
                                        {getFormattedPrice(planPrice)}
                                    </p>
                                    {!isMonthly ? (
                                        <p className='per-month'>Per Month (Paid annually)</p>
                                    ) : (
                                        <p className='per-month'>Per Month</p>
                                    )}
                                </div>
                                <p className='contract-text'>1 Year Minimum Contract</p>
                                <a
                                    href={isMonthly ? plan.monthlyLink : plan.annualLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <button className="select-button">Continue with {plan.tier} tier</button>
                                </a>

                                <div className="connections-section">
                                    {plan.connections.map((connection, i) => (
                                        <React.Fragment key={i}>
                                            {connection
                                                .split(/(Monitor:|On:|From:)/)
                                                .map((part, j) =>
                                                    part === 'Monitor:' || part === 'On:' || part === 'From:' ? (
                                                        <strong key={j}>{part}</strong>
                                                    ) : (
                                                        <span key={j}>{part}<br /></span>
                                                    )
                                                )}
                                        </React.Fragment>
                                    ))}
                                </div>

                                <div className="using-section" style={{ marginTop: '3rem' }}>
                                    <h3>Using:</h3>
                                    <ul>
                                        {plan.using.map((feature, i) => (
                                            <li key={i}>{feature}</li>
                                        ))}
                                    </ul>
                                </div>

                                <div className="with-section" style={plan.tier === 'Starter' ? { marginTop: '9.5em' } : { marginTop: '3rem' }}>
                                    <h3>With:</h3>
                                    <ul>
                                        {plan.with.map((feature, i) => (
                                            <li key={i}>{feature}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        );
                    })}
                </div>

                <div className='enterprise-card'>
                    <h2 style={{fontSize:'2.5rem', marginTop:'0'}}>Enterprise</h2>
                    <p className='calculation-information'>
                        Enterprise by size or Enterprise by requirement -<br />If you would prefer to customise a plan or build your own:
                    </p>
                    <a href='/contact'>
                        <button className="select-button" style={{maxWidth: '20rem'}}>Contact Sales</button>
                    </a>
                    <div className='enterprise-features-wrapper'>
                        <div className='enterprise-features-box'>
                            <img src={enterpriseLocations} alt="enterprise-locations" />
                            <h3 className='enterprise-features-title'>Custom Cloud-Monitoring Agents</h3>
                            <p className='enterprise-features-text'>Choose from 100+ locations around the World</p>
                        </div>
                        <div className='enterprise-features-box'>
                            <img src={enterpriseAccounts} alt="enterprise-accounts"/>
                            <h3 className='enterprise-features-title'>Additional User Accounts</h3>
                            <p className='enterprise-features-text'>Share Threpoly with Your Team</p>
                        </div>
                        <div className='enterprise-features-box'>
                            <img src={enterpriseHistory} alt="enterprise-history"/>
                            <h3 className='enterprise-features-title'>Increased Monitoring History Retention</h3>
                            <p className='enterprise-features-text'>More data for better historical trending and
                                analysis</p>
                        </div>
                        <div className='enterprise-features-box'>
                            <img src={enterpriseSupport} alt="enterprise-support"/>
                            <h3 className='enterprise-features-title'>Technical Support Coverage</h3>
                            <p className='enterprise-features-text'>We are experts with over 40 years of Experience</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Pricing;
