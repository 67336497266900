import React, { useState } from 'react';
import './UseCaseSlider.css';

const UseCaseSlider = () => {
    const useCases = [
        {
            title: "Enhancing Security & Compliance In Data Transfers",
            challenge:
                "The customer, a large consumer goods & apparel organization, has been using a common MFT tool for years without issues but recently has been struggling to onboard 3rd parties due to SFTP connectivity issues - impacting reputation and delaying partnerships launches.",
            howHelped:
                "Using Threpoly, the customer discovered that they were using aged SFTP ciphers - not supported - by their 3rd parties, which were therefore closing the connections. The customer disabled obsolete algorithms and enabled modern ones, allowing the successful onboarding of those 3rd-parties, and also inadvertently improving compliance with security requirements.",
            businessBenefits: [
                {
                    title: "Seamless Partner Onboarding",
                    subtitle:
                        "Resolve security roadblocks, enabling new partnerships in alignment with the business objectives.",
                },
                {
                    title: "Improved Compliance",
                    subtitle:
                        "Always be aligned with company, industry & 3rd-party security standards.",
                },
                {
                    title: "Lower Security Risks",
                    subtitle:
                        "Proactively resolve vulnerabilities before they can be exploited.",
                },
                {
                    title: "Reduced Costs",
                    subtitle:
                        "Avoid downtime & penalties as a result of unnecessary emergency fixes.",
                },
            ],
            keyFeatures: [
                {
                    title: "Security Score Dashboard",
                    subtitle:
                        "Real-time assessment of ciphers, algorithms & software versions.",
                    image: "/UseCaseImages/SecurityScoreDial.png",
                },
                {
                    title: "Automated Risk Scoring",
                    subtitle: "Continuous checks against vulnerability databases.",
                    image: "/UseCaseImages/SecurityAlgos.png",
                },
                {
                    title: "Proactive Alerts",
                    subtitle: "Warnings for outdated, EOL, or insecure components.",
                    image: "/UseCaseImages/SecurityNotification.png",
                },
                {
                    title: "Trend Analysis",
                    subtitle: "Track security improvements over time.",
                    image: "/UseCaseImages/SecurityGraph.png",
                },
            ],
        },
        {
            title: "Preventing Business Disruption with Keyframe Markers",
            challenge:
                "The customer, a global supply chain & logistics organization, uses an MFT system to securely transfer shipping schedules, inventory updates, and invoices. A new patch for their MFT software has been released, including new features which the customer wishes to make use of. However, they want to test the impact of the patch on latency, and how this would impact critical file transfers.",
            howHelped:
                "The IT team applied the patch to their non-production environment and made use of a Threpoly Keyframe Marker to track performance metrics before and after the event. This clearly showed an increase in latency and errors post-event, demonstrating that the patch would increase latency if applied to production. The customer provided this information to the vendor, who later issued a revised patch which corrected the issue.",
            businessBenefits: [
                {
                    title: "Risk Mitigation",
                    subtitle: "Validate the true impact of patches before application in production.",
                },
                {
                    title: "Avoid Disruption",
                    subtitle:
                        "Prevent unnecessary downtime, slowdowns & errors in critical file transfers.",
                },
                {
                    title: "Stronger Vendor Collaboration",
                    subtitle: "Improve issue resolutions with data-driven evidence.",
                },
                {
                    title: "Protect Business Reputation",
                    subtitle: "Ensure seamless file transfers.",
                },
            ],
            keyFeatures: [
                {
                    title: "Keyframe Markers Visual Correlation",
                    subtitle:
                        "Compare performance before and after each patch and track changes.",
                    image: "/UseCaseImages/KeyframeGraph.png",
                },
                {
                    title: "Plan Ahead & Optimize Workflows",
                    subtitle: "",
                    image: "/UseCaseImages/KeyframeRadar.png",
                },
            ],
            video: "/videos/Keyframe-UseCases.mp4",
        },
        {
            title: "Handling Peak Retail Campaigns",
            challenge:
                "The customer, a global retail chain, was preparing to launch a promotional campaign that would significantly increase order volumes and require their MFT system to process a higher number of transactions. Without insights into how the system would handle an unpredictable traffic surge, they risked system congestion, delayed transfers, failed connections, missed SLA, and lost revenue.",
            howHelped:
                "By using Threpoly, the customer gained the ability to forecast performance under heavy loads, identify potential bottlenecks, and optimize workflows to ensure seamless operations during peak demand. This helped them absorb the impact of their new campaign without affecting existing transfers.",
            businessBenefits: [
                {
                    title: "Fine-Tuned Workflows",
                    subtitle: "Maintain optimal performance with scheduled transfers.",
                },
                {
                    title: "Seamless Scaling",
                    subtitle: "No failed connections during high traffic periods.",
                },
                {
                    title: "100% SLA Compliance",
                    subtitle: "Ensure customer and partner satisfaction during peak demand.",
                },
            ],
            keyFeatures: [
                {
                    title: "Real-time Congestion Detection",
                    subtitle: "Compare current performance against historical data.",
                    image: "/UseCaseImages/ForecastGraph.png",
                },
                {
                    title: "Predictive Congestion Detection",
                    subtitle: "View upcoming busy periods on Threpoly's application fingerprints.",
                    image: "/UseCaseImages/ForecastFingerprint.png",
                },
            ],
        },
        {
            title: "Ensure Cloud MFT Performance & SLA Compliance",
            challenge:
                "The customer, an e-commerce enterprise, relies on a cloud MFT provider for its daily exchange of order confirmations, inventory updates, and payment processing files. They started experiencing frequent delays and sporadic downtimes, impacting business operations and customer satisfaction. Cloud MFT providers promised 99.9% uptime but offered no transparent mechanism to verify service availability and performance.",
            howHelped:
                "Using Threpoly, the customer gained unparalleled visibility into the performance, availability, and compliance of their cloud MFT instance, enabling them to hold their provider accountable to SLA terms and ensure seamless operations.",
            businessBenefits: [
                {
                    title: "SLA Verification",
                    subtitle:
                        "Check whether cloud MFT providers meet their SLA with indisputable performance data and auditable evidence of compliance or breach.",
                },
                {
                    title: "Guarantee Validation",
                    subtitle:
                        "Be certain that the SLAs offered to your trading partners are achievable.",
                },
                {
                    title: "Service Provider Improvement",
                    subtitle:
                        "Use data-driven evidence to improve your provider's offering.",
                },
                {
                    title: "Negotiation Leverage",
                    subtitle:
                        "Negotiate refunds or service credits for unmet SLAs with transparent reporting.",
                },
            ],
            keyFeatures: [
                {
                    title: "Uptime & SLA Compliance Verification",
                    subtitle: "Real-time monitoring and historical reporting.",
                    image: "/UseCaseImages/FailureStats.png",
                },
                {
                    title: "Transfer Speed & Latency Tracking",
                    subtitle: "Pinpoint performance issues.",
                    image: "/UseCaseImages/FailureTooltip.png",
                },
                {
                    title: "Auditable Reports",
                    subtitle: "For service credits and vendor accountability.",
                    image: "/UseCaseImages/FailureFingerprint.png",
                },
            ],
        },
        {
            title: "Seamless MFT Migration from On-Premises to Cloud",
            challenge:
                "The customer, a financial services firm, decided to migrate its on-premises MFT system to a cloud-based platform to improve scalability and reduce operational costs. They faced challenges in understanding their baseline performance and ensuring a smooth transition without disrupting critical file transfer operations.",
            howHelped:
                "Using Threpoly, the customer conducted a comprehensive pre-migration analysis, continuously monitored performance during the transition, and proactively resolved issues, resulting in a seamless and successful process. They also performed post-migration validation to demonstrate optimization.",
            businessBenefits: [
                {
                    title: "Smoother Transition",
                    subtitle: "No migration-related errors.",
                },
                {
                    title: "Zero Downtime",
                    subtitle: "No disruptions to critical file transfers.",
                },
                {
                    title: "Faster Migration",
                    subtitle: "Reduced costs and optimized resources.",
                },
                {
                    title: "Performance Validation",
                    subtitle: "Cloud solution performs better than on-premises setup.",
                },
            ],
            keyFeatures: [
                {
                    title: "Real-Time Monitoring Insight",
                    subtitle: "Timelines during the mighration - Key alerts and issue resolutions.",
                    image: "/UseCaseImages/MigrationGraph.png",
                },
                {
                    title: "Comparative Analysis",
                    subtitle: "Pre/Post migration performance.",
                    image: "/UseCaseImages/MigrationRadar.png",
                },
            ],
        },
        {
            title: "Diagnosing Performance Issues in Data Transfers",
            challenge:
                "The customer, a sportswear and lifestyle brand, trades via the internet and relies on the communication between their e-commerce site and their warehouses. On occasion, orders are not reaching the warehouses quickly enough, causing missed parcel dispatch SLA. The customer’s network monitoring tool shows 'all systems healthy' and no congestion.",
            howHelped:
                "Threpoly's continuous synthetic monitoring tracks latency at every stage of the connection. Occasional spikes were detected during the SSH handshake and session establishment phases, allowing the IT team to focus their troubleshooting on the authentication process instead of examining multiple systems, significantly reducing diagnosis time.",
            businessBenefits: [
                {
                    title: "Faster Resolution",
                    subtitle: "Pinpoint and resolve issues efficiently.",
                },
                {
                    title: "Business Reputation",
                    subtitle: "Address issues before real users are affected.",
                },
                {
                    title: "Reduce Costs",
                    subtitle: "Remove bloated resolution periods.",
                },
                {
                    title: "Confidence in Communication",
                    subtitle: "Confirm the validity of a fix before communicating with customers.",
                },
            ],
            keyFeatures: [
                {
                    title: "Real-Time Synthetic Monitoring",
                    subtitle: "Quickly identify issues as they occur in production, and precisely address the root cause.",
                    image: "/UseCaseImages/LiveMonitoring.png",
                },
                {
                    title: "Proactive Detection",
                    subtitle: "Detect performance changes to prevent outages.",
                    image: "/UseCaseImages/MigrationRadar.png",
                },
            ],
        },
        {
            title: "Detecting Long-Term Performance Degradation",
            challenge:
                "The customer, a financial services provider, relies on a cloud-based MFT system to process critical transactions. Over one month, the system started suffering a gradual performance decline, resulting in a 10% increase in transfer times. Internal monitoring tools didn’t detect any anomalies, leaving the customer unable to intervene early.",
            howHelped:
                "Threpoly’s advanced monitoring and analytics capabilities detected both long-term degradation and short-term spikes that indicated initial server difficulty. Real-time and historical insights enabled informed decisions to address the underlying issues early, allowing the customer to avoid a major outage and ensuring uninterrupted operations.",
            businessBenefits: [
                {
                    title: "Avoided Downtime",
                    subtitle:
                        "Early detection prevented outages that could have cost millions.",
                },
                {
                    title: "Protected Client Trust",
                    subtitle:
                        "Ensured business continuity and maintained client trust.",
                },
            ],
            keyFeatures: [
                {
                    title: "Macro-Level Historical Monitoring",
                    subtitle:
                        "Reveals gradual, steady increase in latency over time.",
                    image: "/UseCaseImages/MacroGraph.png",
                },
                {
                    title: "Micro-Level Real-Time Alerting",
                    subtitle: "Detects sudden performance spikes.",
                    image: "/UseCaseImages/MicroNotifcation.png",
                },
            ],
        },
    ];


    const [currentSlide, setCurrentSlide] = useState(0);
    const currentCase = useCases[currentSlide];

    return (
        <div className="ucs-slider-container">
            <div className="ucs-usecase-title-box">
                <h1>{currentCase.title}</h1>
                <div className="ucs-slider-controls">
                    <button onClick={() => setCurrentSlide((currentSlide - 1 + useCases.length) % useCases.length)}>
                        ◀
                    </button>
                    <div className="ucs-slider-indicators">
                        {useCases.map((_, index) => (
                            <div
                                key={index}
                                className={`ucs-indicator-box ${currentSlide === index ? "active" : ""}`}
                                onClick={() => setCurrentSlide(index)}
                            />
                        ))}
                    </div>
                    <button onClick={() => setCurrentSlide((currentSlide + 1) % useCases.length)}>
                        ▶
                    </button>
                </div>
            </div>
            <div key={currentSlide} className="ucs-slide">
                {/* The Challenge Section */}
                <div className="ucs-challenge-and-video" >
                    {currentCase.video && (
                        <div className="ucs-challenge-and-help-withVideo">
                            <section className="ucs-section ucs-challenge-section">
                                <h2>The Challenge</h2>
                                <p>{currentCase.challenge}</p>
                            </section>
                            {/* How Threpoly Helped Section */}
                            <section className="ucs-section ucs-how-helped-section">
                                <h2>How Threpoly Helped</h2>
                                <p>{currentCase.howHelped}</p>
                            </section>
                        </div>
                    )}

                    {!currentCase.video && (
                        <div style={{width:'100%'}}>
                            <section className="ucs-section ucs-challenge-section">
                                <h2>The Challenge</h2>
                                <p>{currentCase.challenge}</p>
                            </section>
                            {/* How Threpoly Helped Section */}
                            <section className="ucs-section ucs-how-helped-section">
                                <h2>How Threpoly Helped</h2>
                                <p>{currentCase.howHelped}</p>
                            </section>
                        </div>
                    )}

                    {/* Conditional Video Section */}
                    {currentCase.video && (
                        <section className="ucs-video-section">
                            <video controls>
                                <source src={currentCase.video} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </section>
                    )}
                </div>


                {/* Key Features Section */}
                <section className="ucs-section ucs-key-features-section">
                    <h2>Threpoly’s Relevant Key Features</h2>
                    <div className="ucs-key-features-wrapper">
                        {currentCase.keyFeatures.map((feature, index) => (
                            <div key={index} className="ucs-feature-item">
                                <img
                                    src={feature.image}
                                    alt={feature.title}
                                    className="ucs-feature-image"
                                />
                                <div className="ucs-feature-content">
                                    <h3 className="ucs-feature-title">{feature.title}</h3>
                                    <p className="ucs-feature-subtitle">{feature.subtitle}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </section>

                {/* Business Benefits Section */}
                <section className="ucs-section ucs-business-benefits-section">
                    <h2>Business Benefits</h2>
                    <div className="ucs-key-features-wrapper">
                        {currentCase.businessBenefits.map((benefit, index) => (
                            <div key={index} className="ucs-benefit-item">
                                <h3 className="ucs-benefit-title">{benefit.title}</h3>
                                <p className="ucs-benefit-subtitle">{benefit.subtitle}</p>
                            </div>
                        ))}
                    </div>
                </section>
            </div>
            <div className="ucs-slider-controls">
                <button onClick={() => setCurrentSlide((currentSlide - 1 + useCases.length) % useCases.length)}>
                    ◀
                </button>
                <div className="ucs-slider-indicators">
                    {useCases.map((_, index) => (
                        <div
                            key={index}
                            className={`ucs-indicator-box ${currentSlide === index ? "active" : ""}`}
                            onClick={() => setCurrentSlide(index)}
                        />
                    ))}
                </div>
                <button onClick={() => setCurrentSlide((currentSlide + 1) % useCases.length)}>
                    ▶
                </button>
            </div>
        </div>
    );
};

export default UseCaseSlider;