import React, { useEffect, useState } from 'react';
import './Hero.scss';
import DownloadDataSheet from "./DownloadDataSheet";
// Removed the ExampleWidget import since it's no longer needed
// import ExampleWidget from './ExampleWidget'
import capture from '../Images/Threpoly_App_Capture.png'

// It's generally recommended to use MP4 for better browser compatibility
import reportVideo from '../Images/Report Graph.mp4' // Ensure this path is correct
// Import a separate fallback image for the video. Replace with your actual fallback image path.
import reportVideoFallback from '../Images/Report Graph Fallback.jpg' // Example fallback image

const Hero = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showDownload, setShowDownload] = useState(false); // State for showing DownloadDataSheet
  const [videoError, setVideoError] = useState(false); // State to track video load error

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setScrollPosition(scrollTop);

      // Show DownloadDataSheet after the user scrolls 150px or more
      if (scrollTop > 150) {
        setShowDownload(true);
      } else {
        setShowDownload(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const calculateStyle = (index) => {
    const offset = 30 * (index + 10); // Adjust this multiplier to control the animation speed
    const opacity = Math.max(1 - scrollPosition / (offset + 400), 0);
    return { opacity };
  };

  return (
      <div className='image-background'>
        <div className="container">
          <section className="hero" style={calculateStyle(2)}>
            <div className='hero-content'>
              <h1>Achieve File Transfer Excellence</h1>
              <p>
                Threpoly continually monitors and assesses your file transfer and data exchange infrastructure for operational efficiency, performance, and security hygiene.<br/><br/>Keep
                your data flows <span className='underline'>optimal</span>, and your business <span className='underline'>functional</span>.
              </p>
              <a href="/contact"><button>Book a demo</button></a>
            </div>
              <img
                  className="screenshot"
                  src={capture}
                  alt="Threpoly SFTP Monitor screenshot"
                  style={calculateStyle(2)}
              />
          </section>
        </div>
        {/* Conditionally render DownloadDataSheet */}
        <div className="lines">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
      </div>
  );
};

export default Hero;
