import React, { useState } from 'react';
import './FAQ.css';

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqItems = [
    {
    question: "What is Threpoly?",
    answer: "Threpoly is a cloud-based SaaS solution used to measure and report back on the performance and security hygiene of solutions which use commonplace file transfer protocols. Threpoly has features such as multiple geographically spread agents to test regional performance; synthetic user tests which simulate user activity and therefore be able to report back on the performance of individual steps in the transaction; and automated alerting and notification where service degradation, anomalies or outages are detected."
    },
    {
    question: "Which Types of Solutions Does Threpoly Support?",
    answer: "Threpoly connects to SFTP and FTPS solution interfaces to gather diagnostic information. This means Threpoly supports solutions such as FT (File Transfer), MFT (Managed File Transfer) and EDI (Electronic Data Interchange). Threpoly remains solution agnostic, focussing on protocol support, rather than solution support."
    },
    {
    question: "Can Threpoly Fix Issues with File Transfer Solutions?",
    answer: "Threpoly doesn’t fix or rectify issues with solutions or network infrastructure, instead it helps to identify areas of problem or developing problem for the purpose of correction or adaptation. The information which Threpoly is able to derive from its various provides insights which are not natively available in the file transfer solution itself."
    },
    {
        question: "In Which Countries is Threpoly Available?",
        answer: "Threpoly is available to an international audience, and can currently simulate traffic from three regions - Europe, North America and Asia Pacific. We plan to add support for customising these regions at a later date and plan to add additional language support too."
      },
    {
        question: "What is Managed File Transfer?",
        answer: "Managed File Transfer describes the centralisation and control of all file transfers in an Organisation, to a set or single solution which is able to conduct the file transfer and provide auditability. MFT solutions will typically make use of standardised and open protocols such as FTP, FTPs, SFTP, PESIT and AS4 to receive and send files. It is commonplace in today's MFT solution market that solutions also include automated file transfer workflow capabilities, supporting manipulation and integration with wider solution types."
    }
  ];

  return (
    <div className="container" style={{zIndex: 10000}}>
        <div className='faq-container'>
            <h1 className="faq-title">Frequently Asked Questions</h1>
            {faqItems.map((item, index) => (
                <div key={index} className="faq-item">
                <button
                    className={`faq-question ${activeIndex === index ? 'active' : ''}`}
                    onClick={() => toggleAccordion(index)}
                >
                    {item.question}
                </button>
                <div
                    className={`faq-answer ${activeIndex === index ? 'open' : ''}`}
                    style={{ maxHeight: activeIndex === index ? '200px' : '0' }}
                >
                    <p>{item.answer}</p>
                </div>
                </div>
            ))}
        </div>
    </div>
  );
};

export default FAQ;
