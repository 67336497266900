import React from 'react';
import './DownloadDataSheet.css';
import downloadIcon from '../Images/download.png';
import datasheetThumbnail from '../Images/Threpoly_Datasheet.png';

const DownloadDataSheet = ({ }) => {
    return (
        <div className="download-datasheet">

            <div className="download-datasheet-wrapper">
                <h2 className="download-datasheet-heading">Learn more about our solution</h2>
                <a href="/downloads/Threpoly_Datasheet_2025.pdf" target='_Blank'>
                    <div className="download-datasheet-container">
                        <h2 className="download-data-sheet-title">Download the data sheet (1.3MB)</h2>

                        <div className="download-data-button">
                            <img src={downloadIcon} className="download-icon" alt="Download"/>
                        </div>
                    </div>
                </a>
            </div>
    <div className="download-datasheet-thumbnail-wrapper">
                <img className="download-datasheet-thumbnail" src={datasheetThumbnail} alt="Threpoly_Datasheet"/>
            </div>
        </div>
    );
};

export default DownloadDataSheet;
