import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import './FAQ.css';

const UseCasesFAQ = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const faqItems = [
        {
            question: "Which security measures does Threpoly employ to prevent unauthorised access or data breaches?",
            answer: `Threpoly is a cloud-based solution - without any on-prem components - which stores a very limited number of personal data items related only to the users of the Threploy service; and has no access to customer data exchange solutions or network environments. Therefore the risk associated with a breach is low.

Threpoly employs a number of controls to protect the limited personal data held by the service.
- **Encryption:** All personal data is encrypted using AES 256.
- **Access Controls:** Role-based access control (RBAC) prevents non-administrator users from viewing user account information.
- **Authentication & Identity Management:** Supports federated authentication against Microsoft o365 and Google Suite, benefiting from centralized account control, multi-factor authentication, and context-based authentication.
- **Network Security:** Uses firewalls, private network configurations, and strict access policies to restrict and monitor network access.
- **Secrets Management:** Leverages an external secret store to securely store keys and passwords, ensuring they are never exposed in plaintext.
- **Automated Threat Detection:** Employs threshold-based monitoring and predefined security rules to proactively identify and mitigate risks.`
        },
        {
            question: "Which key performance indicators (KPIs) does Threpoly track to assess operational efficiency?",
            answer: `Threpoly tracks a comprehensive set of KPIs to assess operational efficiency and the performance of data exchange solutions. These include:
- **Latency:** Measures the time taken for SFTP and FTPs sessions to be established and a LIST command to return a result, helping identify performance bottlenecks and the stages of session establishment which are affected.
- **Variability:** Tracks fluctuations in latency and transfer times to warn of anomalous behaviour and potential service instability/interruption.
- **SLA Uptime:** Monitors service availability to ensure your or your service providers compliance with uptime commitments.
- **Failure Rate:** Analyses the percentage of failed synthetic user tests to identify recurring issues and steer customers toward improving reliability.
- **Outage Periods:** Logs and reports downtime events, helping businesses to correct service issues and assess system resilience.
- **Maintenance Periods:** Tracks scheduled and ad-hoc maintenance windows to distinguish between expected and unexpected downtime.
- **Transfer Speed:** Evaluates data throughput efficiency via a synthetic upload and download test to help customers optimise performance.
- **Security Score:** Continuously assesses security risks posed by insufficient cryptographic algorithms, data exchange software version and service variability.

By monitoring these KPIs, Threpoly provides real-time and long-term visibility into system performance, enabling organisations to proactively address issues, optimise workflows, and work towards compliance with regulatory and operational standards.`
        },
        {
            question: "How does Threpoly handle high-volume or complex data exchange environments without impacting system performance?",
            answer: `Threpoly was initially designed, tested, and built for one of the world’s largest companies. Using a lightweight, low-impact approach, it excels in high-volume and complex environments without affecting performance.

Our approach includes:
- **Cloud-First:** 100% hosted in the cloud with no on-prem components.
- **Non-Intrusive Monitoring:** Uses regular synthetic user tests to simulate the experience of trading partners without interfering with live transfers.
- **Customisable Transfer Speed Testing:** Allows users to define file sizes and testing intervals, controlling the impact on their environment.

These methods ensure deep performance insights with minimal overhead, even under demanding conditions.`
        },
        {
            question: "Which mechanisms does Threpoly use to optimise file transfer speed and reliability?",
            answer: `Threpoly continuously analyzes performance trends via synthetic user testing to optimize file transfer speed and reliability. Key mechanisms include:
- **Historical Data Analysis:** Detects recurring bottlenecks, slowdowns, and outages in connection establishment and file transfers.
- **Real-Time Performance Warnings:** Provides alerts during active performance issues for quick diagnosis and resolution.
- **Stability and Congestion Forecasting:** Uses past trends to assess future performance and identify windows of stability for capacity planning.

By leveraging both historical insights and real-time monitoring, Threpoly helps businesses maintain high-speed, reliable file transfers and prevent unexpected downtime.`
        },
        {
            question: "Is Threpoly compatible with various file transfer protocols (e.g., FTP, SFTP, HTTP, API-based transfers)?",
            answer: `Yes, Threpoly is compatible with various file transfer protocols. Currently, it supports SFTP and FTPS. Future roadmap items include HTTPS-based transfers, PESIT, AS2, IBM, and Connect:Direct.

Threpoly continually monitors the data exchange and file transfer marketplaces to ensure it meets the needs of the largest number of users.`
        }
    ];

    return (
        <div className="container" style={{ zIndex: 10000 }}>
            <div className='faq-container'>
                <h1 className="faq-title">Frequently Asked Questions</h1>
                {faqItems.map((item, index) => (
                    <div key={index} className="faq-item">
                        <button
                            className={`faq-question ${activeIndex === index ? 'active' : ''}`}
                            onClick={() => toggleAccordion(index)}
                        >
                            {item.question}
                        </button>
                        <div
                            className={`faq-answer ${activeIndex === index ? 'open' : ''}`}
                            style={{ maxHeight: activeIndex === index ? 'fit-content' : '0' }}
                        >
                            <ReactMarkdown>{item.answer}</ReactMarkdown>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default UseCasesFAQ;
